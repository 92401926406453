@import "src/mixins/screen-size";
@import "../../assets/scss/theme.scss";

.without-shadow {
  box-shadow: none !important;
}

.risk-button {
  margin: 0.2rem !important;
}

.card-detail-inside {
  background-color: transparent;
  border: none;
  box-shadow: none !important;
  text-align: center;
}

.no-margin {
  margin: 0px !important;
  max-width: 100% !important;
}

.cashflow-title {
  h4 {
    color: $secondary;
  }
  @include for-size(desktop-up) {
    padding-left: 20px !important;
  }
  @include for-size(tablet-landscape-up) {
    padding-left: 20px !important;
  }
  @include for-size(tablet-portrait-up) {
    padding-left: 20px !important;
  }
}

.centered {
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}

.with-padding {
  padding: 20px;
  width: 100%;
  height: 90%;
}

.days-buttons-padding {
  @include for-size(desktop-up) {
    padding-right: 70px;
  }
  @include for-size(tablet-landscape-up) {
    padding-right: 70px;
  }
  @include for-size(tablet-portrait-up) {
    padding-right: 70px;
  }
}

.params {
  padding-left: 20px;
  padding-top: 20px;
  color: gray !important;
  font-size: 18px;
}

.params-value {
  color: $secondary-dark !important;
}

.widget-value {
  color: $secondary !important;
}
